<template>
  <el-container style="height: 100%; width: 100%">
    <el-header style="width: 100%; height: 100px; padding: 10px">
      <el-image
        style="width: 100%; height: 100px"
        src="https://oss.cniot.fun/sysProgram/head-logo.jpg"
      ></el-image>
    </el-header>
    <el-main
      class="public-scroll-transparent public-page-style iot-gateway-main"
    >
      <el-card
        style="
          width: calc(100% - 10px);
          border: none;
          box-shadow: none;
          height: calc(100% - 80px);
        "
      >
        <div slot="header" class="clearfix">
          <el-form :inline="true">
            <el-form-item>
              <el-form-item label="联网方式：">
                <el-select
                  v-model="listData.networkModeId"
                  multiple
                  clearable
                  filterable
                  size="mini"
                  placeholder="请选择"
                  default-first-option
                  :multiple-limit="1"
                >
                  <el-option
                    v-for="item in networkList"
                    :key="item.networkModeId"
                    :label="item.networkMode"
                    :value="item.networkModeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="网关名称：">
                <el-input
                  size="mini"
                  clearable
                  v-model="listData.gatewayName"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click.stop="selectData"
                  >查询</el-button
                >
              </el-form-item>
            </el-form-item>
            <el-button
              class="iot-create-btn"
              type="primary"
              plain
              size="mini"
              v-if="$store.state.permissionArr.indexOf('gateway:add') > -1"
              @click="addGateway()"
              >新增</el-button
            >
          </el-form>
        </div>
        <div class="iot-gateway-body">
          <!-- 设计允许 -->
          <div
            v-for="item in gatewayData"
            :key="item.gatewayId"
            style="
              width: 30%;
              height: 29%;
              display: inline-block;
              margin: 8px 1.5%;
              float: left;
              padding: 10px;
              border: 1px solid #d9d9d9;
              box-sizing: border-box;
            "
          >
            <div
              style="
                height: 100%;
                width: 35%;
                display: inline-block;
                float: left;
              "
            >
              <h4 style="margin-block-start: 0em; margin: 10px 0">
                {{ item.gatewayName }}
              </h4>
              <div style="color: #b4b4b4; font-size: 12px" :title="item.remark">
                {{ item.remark }}
              </div>
            </div>
            <el-image
              style="
                height: 90%;
                width: 20%;
                display: inline-block;
                float: left;
                padding-top: 5%;
              "
              :src="constant.OSS_URL + constant.GATEWAY_IMG + item.gatewayImg"
            ></el-image>
            <div
              style="
                position: relative;
                height: 100%;
                display: inline-block;
                width: calc(100% - 35% - 100px);
              "
            >
              <el-link
                style="float: right; margin: 10px"
                type="primary"
                :underline="false"
                @click="settingDetail(item.gatewayId)"
                >网关绑定设备</el-link
              >
              <div
                style="
                  width: 100%;
                  position: absolute;
                  bottom: 0;
                  right: 5px;
                  text-align: right;
                "
              >
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  :underline="false"
                  v-show="
                    $store.state.permissionArr.indexOf('gateway:update') > -1
                  "
                  @click.native="editGateway(item.gatewayId)"
                  >修改</el-button
                >
                &nbsp;
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  :underline="false"
                  v-show="
                    $store.state.permissionArr.indexOf('gateway:delete') > -1
                  "
                  @click.native="delGateway(item.gatewayId)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
          <!--<div
            class="iot-gateway-item"
            v-for="item in gatewayData"
            :key="item.gatewayId"
          >
            <div
              style="
                width: 25%;
                height: 100%;
                display: inline-block;
                padding-left: 5px;
              "
            >
              <h4>{{ item.gatewayName }}</h4>
              <div class="remark" :title="item.remark">{{ item.remark }}</div>
            </div>
            <div class="iot-gateway-item-image">
              <el-image
                :src="constant.OSS_URL + constant.GATEWAY_IMG + item.gatewayImg"
              ></el-image>
            </div>
            <div class="iot-gateway-item-links">
              <el-link
                type="primary"
                :underline="false"
                @click="settingDetail(item.gatewayId)"
                >设备实例管理</el-link
              >
              <div>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  :underline="false"
                  v-show="
                    $store.state.permissionArr.indexOf('gateway:update') > -1
                  "
                  @click.native="editGateway(item.gatewayId)"
                  >修改</el-button
                >
                &nbsp;
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  :underline="false"
                  v-show="
                    $store.state.permissionArr.indexOf('gateway:delete') > -1
                  "
                  @click.native="delGateway(item.gatewayId)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>-->
        </div>
      </el-card>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listData.current"
        :page-sizes="[9, 18, 27, 36]"
        :page-size="listData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 20px; margin-right: 30px; float: right"
      ></el-pagination>
    </el-main>
    <!-- 新增网关表单 -->
    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="gatewayDialogVisibile"
      :before-close="handleClose"
      :destroy-on-close="false"
      :close-on-click-modal="false"
      class="iot-dialog"
      width="33%"
    >
      <div slot="title" style="">
        <img
          style="width: 25px; vertical-align: middle; margin-top: -5px"
          src="../assets/pencil.png"
        />
        <div style="margin-left: 7px; padding-top: 15px; display: inline-block">
          {{ formTitle }}
        </div>
      </div>
      <el-form
        ref="gatewayRef"
        :model="gatewayForm"
        label-width="120px"
        label-position="left"
        :rules="rules"
      >
        <el-form-item label="网关名称" prop="gatewayName">
          <el-input v-model="gatewayForm.gatewayName"></el-input>
        </el-form-item>
        <el-form-item label="网关说明">
          <el-input
            type="textarea"
            maxlength="100"
            show-word-limit
            placeholder="请输入网关说明"
            :autosize="{ minRows: 8 }"
            v-model="gatewayForm.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联网方式" prop="networkModeId">
          <el-select
            v-model="gatewayForm.networkModeId"
            filterable
            placeholder="请选择"
            @change="selectNetworkMode"
          >
            <el-option
              v-for="item in networkList"
              :key="item.networkModeId"
              :label="item.networkMode"
              :value="item.networkModeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitGatewayForm()"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="gatewayDialogVisibile = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <!-- 实例设备列表 -->
    <el-dialog
    top="3%"
      class="gateway-dialog"
      :title="formTitle"
      :show-close="false"
      :visible.sync="detailDialogVisibile"
      :before-close="handleClose"
      :destroy-on-close="false"
      :close-on-click-modal="false"
      width="50%"
    >
      <div class="iot-gateway-form">
        <el-form>
          <el-form-item label="">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="公共设备" name="0">
                <div class="iot-type-items">
                  <span>行业：</span>
                  <el-tag
                    :type="0 == orForm.industryId ? '' : 'info'"
                    class="iot-type-item"
                    @click="tabClick(0)"
                    >全部</el-tag
                  >
                  <el-tag
                    :type="item.industryId == orForm.industryId ? '' : 'info'"
                    class="iot-type-item"
                    v-for="item in industryList"
                    :key="item.industryId"
                    @click="tabClick(item.industryId)"
                    >{{ item.industryName }}</el-tag
                  >
                </div>
                <div class="iot-gateway-items">
                  <el-card
                    v-for="item in orData"
                    :key="item.originalId"
                    class="iot-or-item"
                    shadow="hover"
                    @click.native="addSubOriginal(item.originalId)"
                  >
                    <el-dropdown>
                      <div class="or-item">
                        <el-image
                          :src="
                            constant.OSS_URL +
                            constant.ORIGINAL_IMG +
                            item.originalImg
                          "
                        ></el-image>
                        <div
                          style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ item.originalName }}
                        </div>
                      </div>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          {{ item.originalName }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-card>
                </div>
              </el-tab-pane>
              <el-tab-pane label="私有设备" name="1">
                <div class="iot-type-items">
                  <span>行业：</span>
                  <el-tag
                    :type="0 == orForm.industryId ? '' : 'info'"
                    class="iot-type-item"
                    @click="tabClick(0)"
                    >全部</el-tag
                  >
                  <el-tag
                    :type="item.industryId == orForm.industryId ? '' : 'info'"
                    class="iot-type-item"
                    v-for="item in industryList"
                    :key="item.industryId"
                    @click="tabClick(item.industryId)"
                    >{{ item.industryName }}</el-tag
                  >
                </div>
                <div class="iot-gateway-items">
                  <el-card
                    v-for="item in orData"
                    :key="item.originalId"
                    class="iot-or-item"
                    shadow="hover"
                    @click.native="addSubOriginal(item.originalId)"
                  >
                    <el-dropdown>
                      <div class="or-item">
                        <el-image
                          :src="
                            constant.OSS_URL +
                            constant.ORIGINAL_IMG +
                            item.originalImg
                          "
                        ></el-image>
                        <div
                          style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ item.originalName }}
                        </div>
                      </div>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          {{ item.originalName }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-card>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <div class="box-card">
            <el-dialog
            top="3%"
              width="50%"
              title="已选参数"
              :before-close="gatewayParamhandleClose"
              :visible.sync="gatewayParamVisible"
              append-to-body
            >
             <div class="tips">
          温馨提醒：“输入框输入序号可以进行排序！”
        </div>
              <div style="height: 485px">
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    overflow-y: auto;
                  "
                >
                  <el-tag
                    v-for="item in gatewayParamList"
                    :key="item.paramId"
                    class="getwayItem"
                    type="success"
                    ><div style="width:160px">{{ item.paramName }}</div>
                    <div>
                      <el-input size="mini"  v-model="item.orderNum" placeholder="排序"></el-input>
                      </div></el-tag
                  >
                </div>
              </div>
        <div slot="footer" class="gateway-but">
           <el-button size="mini" type="primary" @click="updateOrder">确 定</el-button>
        <el-button size="mini" type="info" @click="gatewayParamVisible=false">关 闭</el-button>
      </div>
            </el-dialog>
            <div style="display: flex; justify-content: space-between">
              <span style="line-height: 30px">已选：</span>

              <el-tag
                v-if="paramNum <= 400"
                type="success"
                style="float: right; cursor: pointer"
                @click="getGatewayParams"
                >已选参数{{ paramNum }}个</el-tag
              >
              <!-- <el-tag
                v-if="
                  subOrForm.paramIds.length <= 80 &&
                  subOrForm.paramIds.length > 40
                "
                type="info"
                style="float: right"
                >已选参数{{ subOrForm.paramIds.length }}个</el-tag
              >
              <el-tag
                v-if="
                  subOrForm.paramIds.length <= 120 &&
                  subOrForm.paramIds.length > 80
                "
                type="warning"
                style="float: right"
                >已选参数{{ subOrForm.paramIds.length }}个</el-tag
              > -->
              <el-tag v-if="paramNum > 400" type="danger" style="float: right"
                >已选参数超出上限{{ paramNum - 400 }}个</el-tag
              >
            </div>

            <el-form-item label="">
              <div class="iot-subOriginal-items">
                <el-card
                  v-for="(item, index) in subOriginalList"
                  :key="index"
                  class="iot-subOriginal-select-item"
                  shadow="hover"
                >
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="
                      constant.OSS_URL +
                      constant.ORIGINAL_IMG +
                      item.subOriginalImg
                    "
                  ></el-image>
                  <div class="iot-original-name">
                    {{ item.subOriginalName }}
                  </div>
                  <el-button
                    type="success"
                    plain
                    size="mini"
                    v-if="
                      $store.state.permissionArr.indexOf('subOriginal:update') >
                      -1
                    "
                    @click.native="editSubOr(item)"
                    >修 改</el-button
                  >
                  <el-button
                    type="danger"
                    plain
                    size="mini"
                    v-if="
                      $store.state.permissionArr.indexOf('subOriginal:delete') >
                      -1
                    "
                    @click.native="delSubOr(item.subOriginalId)"
                    >删 除</el-button
                  >
                </el-card>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="gateway-but">
        <el-button size="mini" type="info" @click="closeSubOr">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 设备 -->
    <el-dialog
    top="3%"
      class="subOriginal-dialog"
      :title="formTitle"
      :visible.sync="subOrVisible"
      :before-close="del"
      append-to-body
      :lock-scroll="true"
    >
      <div class="iot-subOriginal-form public-scroll-transparent">
        <el-form
          label-position="left"
          ref="subOriginalRef"
          :model="subOrForm"
          label-width="120px"
          :rules="rules1"
        >
          <el-form-item
            v-if="editSubOriginal"
            label="设备名称"
            prop="subOriginal.subOriginalId"
          >
            <el-input
              readonly
              style="width: 50%"
              placeholder="请输入实例设备编号"
              v-model="subOrForm.subOriginal.parentName"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="editSubOriginal"
            label="实例设备编号"
            prop="subOriginal.subOriginalId"
          >
            <el-input
              readonly
              style="width: 50%"
              placeholder="请输入实例设备编号"
              v-model="subOrForm.subOriginal.subOriginalId"
            ></el-input>
          </el-form-item>
          <el-form-item label="实例设备名称" prop="subOriginal.subOriginalName">
            <el-input
              style="width: 50%"
              placeholder="请输入实例设备名称"
              v-model="subOrForm.subOriginal.subOriginalName"
            ></el-input>
          </el-form-item>
          <el-form-item label="从机地址" prop="config.slaveAddr">
            <el-input-number
              v-model="subOrForm.config.slaveAddr"
              :min="1"
              :max="255"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="波特率" prop="config.baudrate">
            <el-select v-model="subOrForm.config.baudrate" placeholder="请选择">
              <el-option
                v-for="item in constant.baudrate"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="校验位" prop="config.parity">
            <el-radio
              v-model="subOrForm.config.parity"
              v-for="item in constant.parity"
              :key="item.key"
              :label="item.key"
              >{{ item.value }}</el-radio
            >
          </el-form-item>
          <el-form-item label="数据位" prop="config.dataBits">
            <el-radio v-model="subOrForm.config.dataBits" :label="8"
              >8</el-radio
            >
          </el-form-item>
          <el-form-item label="停止位" prop="config.stopBits">
            <el-radio v-model="subOrForm.config.stopBits" :label="1"
              >1</el-radio
            >
          </el-form-item>
          <h4>参数实例列表</h4>
          <el-divider />
          <el-dialog
          top="3%"
            width="60%"
            title="已选参数"
            :visible.sync="seeCheckVisible"
            append-to-body
          >
            <div style="height: 485px">
              <el-collapse accordion>
                <!-- 布尔 -->
                <el-collapse-item name="0">
                  <template style="width: 50px" slot="title">布尔</template>
                  <div
                    style="
                      width: 100%;
                      height: 290px;
                      display: flex;
                      flex-wrap: wrap;
                      overflow-y: auto;
                    "
                  >
                    <el-tag
                      v-for="item in checkedBoolList"
                      :key="item.paramId"
                      class="checkItem"
                      type="success"
                      >{{ item.paramName }}</el-tag
                    >
                  </div>
                </el-collapse-item>
                <!-- 数值 -->
                <el-collapse-item name="1">
                  <template slot="title"> 数值 </template>
                  <div
                    style="
                      width: 100%;
                      height: 290px;
                      display: flex;
                      flex-wrap: wrap;
                      overflow-y: auto;
                    "
                  >
                    <el-tag
                      v-for="item in checkedStrList"
                      :key="item.paramId"
                      class="checkStrItem"
                      type="success"
                      >{{ item.paramName }}</el-tag
                    >
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-dialog>
          <el-form-item class="iot-param-form" label="" prop="paramIds">
            <div
              @click="seeChecked"
              style="
                cursor: pointer;
                position: absolute;
                right: 20px;
                top: -15px;
              "
            >
              <el-tag
                v-if="subOrForm.paramList.length <= 400"
                type="success"
                style="float: right"
                >已选参数列表({{ subOrForm.paramList.length }}个)</el-tag
              >
              <!-- <el-tag
                v-if="
                  subOrForm.paramIds.length <= 80 &&
                  subOrForm.paramIds.length > 40
                "
                type="info"
                style="float: right"
                >已选参数{{ subOrForm.paramIds.length }}个</el-tag
              >
              <el-tag
                v-if="
                  subOrForm.paramIds.length <= 120 &&
                  subOrForm.paramIds.length > 80
                "
                type="warning"
                style="float: right"
                >已选参数{{ subOrForm.paramIds.length }}个</el-tag
              > -->
              <el-tag
                v-if="subOrForm.paramList.length > 400"
                type="danger"
                style="float: right"
                >已选参数列表(超出上限{{
                  subOrForm.paramList.length - 400
                }}个)</el-tag
              >
            </div>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >

            <div style="height: 193px">
              <el-checkbox-group
                style="padding: 5px"
                v-model="subOrForm.paramList"
              >
              <!--布尔值 -->
                <el-collapse accordion v-model="collapseName">
                  <el-collapse-item name="0">
                    <template style="width: 50px" slot="title">布尔</template>
                    <div style="display: flex; flex-wrap: wrap">
                      <div
                        style="width: 280px; margin: 5px 0"
                        v-for="item in paramBolPage"
                        :key="item.paramId"
                      >
                        <el-checkbox :label="item.paramId">
                          <input
                            v-model="item.paramName"
                            type="text"
                            class="paramInput"
                          />
                          <span style="color:black !important">显示曲线：</span>
                          <el-switch 
                            v-model="item.isShowHistory"
                            :active-value="1"
                            :inactive-value="0"
                            @change="change($event,item.isShowHistory)">
                         </el-switch>
                          <!-- <span style="width: 120px; display: inline-block">
                            {{ item.paramName }}</span
                          > -->
                        </el-checkbox>
                      </div>
                    </div>
                    <div class="iot-page">
                      <el-pagination
                        @current-change="getBoolCurrentPage"
                        :current-page="boolCurrentPage"
                        :page-size="50"
                        layout="total, prev, pager, next, jumper"
                        :total="paramBoolList.length"
                        :pager-count="5"
                        :hide-on-single-page="true"
                      >
                      </el-pagination>
                    </div>
                  </el-collapse-item>
                  <!-- 数值 -->
                  <el-collapse-item name="1">
                    <template slot="title"> 数值 </template>
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        font-weight: 1000;
                        font-size: 14px;
                        margin: 5px 0;
                        justify-content: space-between;
                      "
                    >
                      <div
                        v-if="paramStrPage.length > 0"
                        style="
                          width: 400px;
                          display: flex;
                          justify-content: space-between;
                          margin: 5px 10px;
                        "
                      >
                        <span
                          style="
                            width: 150px;
                            display: inline-block;
                            text-align: center;
                            position: relative;
                          "
                        >
                          <!-- <el-checkbox
                          style=" 
 position: absolute;
 left: 0;
 top: 0;
 text-align: left;
 font-width:boder;
 "

              :indeterminate="strisIndeterminate"
              v-model="checkStrPage"
              @change="handleCheckStrChange"
              >全选</el-checkbox
            > -->
                          参数名</span
                        >
                        <span
                          style="
                            width: 60px;padding-left: 15px;
                            display: inline-block;
                            text-align: center;
                          "
                        >
                          小数位</span
                        >
                        <span
                          style="
                            width: 125px;padding-left: 25px;
                            display: inline-block;
                            text-align: center;
                          "
                        >
                          数据属性
                          <el-tooltip
                            style="vertical-align: middle"
                            class="item"
                            effect="dark"
                            content=""
                            placement="right-start"
                          >
                            <div
                              slot="content"
                              style="font-size: 14px; line-height: 26px"
                            >
                              Signed<br />单字有符号
                              <br />Unsiged<br />单字无符号
                              <br />32_Signed_le<br />双字有符号整数，小端
                              <br />32_Signed_le_swap<br />双字有符号整数，小端交换
                              <br />32_Signed_be<br />双字有符号整数，大端
                              <br />32_Signed_be_swap<br />双字有符号整数，大端交换
                              <br />32_Float_Le<br />双字有符号浮点，小端
                              <br />32_Float_Le_swap<br />双字有符号浮点，小端交换
                              <br />32_Float_Be<br />双字有符号浮点，大端
                              <br />32_Float_Be_swap<br />双字有符号浮点，大端交换
                            </div>
                            <img
                              src="@/assets/details.png"
                              style="width: 20px"
                              alt="属性详情"
                            />
                            <!-- <i
                              class="el-icon-question"
                              style="font-size: 20px"
                            ></i> -->
                          </el-tooltip>
                        </span>
                        <span
                          style="
                            width: 60px;margin-left: 15px;
                            display: inline-block;
                            text-align: center;
                          "
                        >
                          显示曲线</span>
                      </div>
                      <div
                        v-if="paramList.length >= 2"
                        style="
                          width: 400px;
                          display: flex;
                          justify-content: space-between;
                          margin: 5px 0px;
                        "
                      >
                        <span
                          style="
                            width: 150px;
                            display: inline-block;
                            text-align: center;
                          "
                        >参数名</span>
                        <span
                          style="
                            width: 60px;padding-left:15px;
                            display: inline-block;
                            text-align: center;
                          ">小数位</span>
                        <span
                          style="
                            width: 125px;padding-left: 35px;
                            display: inline-block;
                            
                          "
                        >
                          数据属性
                          <el-tooltip
                            style="vertical-align: middle"
                            class="item"
                            effect="dark"
                            content=""
                            placement="right-start"
                          >
                            <div
                              slot="content"
                              style="font-size: 14px; line-height: 26px"
                            >
                              Signed<br />单字有符号
                              <br />Unsiged<br />单字无符号
                              <br />32_Signed_le<br />双字有符号整数，小端
                              <br />32_Signed_le_swap<br />双字有符号整数，小端交换
                              <br />32_Signed_be<br />双字有符号整数，大端
                              <br />32_Signed_be_swap<br />双字有符号整数，大端交换
                              <br />32_Float_Le<br />双字有符号浮点，小端
                              <br />32_Float_Le_swap<br />双字有符号浮点，小端交换
                              <br />32_Float_Be<br />双字有符号浮点，大端
                              <br />32_Float_Be_swap<br />双字有符号浮点，大端交换
                            </div>
                            <img
                              src="@/assets/details.png"
                              style="width: 20px"
                              alt="属性详情"
                            />
                            <!-- <i
                              class="el-icon-question"
                              style="font-size: 20px"
                            ></i>  -->
                          </el-tooltip>
                          </span>
                          <span
                          style="
                            width: 60px;margin-left: 15px;
                            display: inline-block;
                            text-align: center;
                          "
                        >
                          显示曲线</span>
                      </div>
                    </div>
                    <!-- 数值下面行 -->
                    <!-- --------------------------------------------------------------------------- -->
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                      "
                    >
                      <div
                        style="width: 400px;display: flex;justify-content: space-between;margin: 5px 0;"
                        v-for="item in paramStrPage"
                        :key="item.paramId"
                      >
                        <el-checkbox :label="item.paramId">
                          <!-- <span style="width: 120px; display: inline-block">
                            {{ item.paramName }}</span
                          > -->
                          <input
                            v-model="item.paramName"
                            type="text"
                            class="paramInput"
                          />
                        </el-checkbox>
                        <el-select
                          v-if="item.paramTypeId != 2"
                          size="mini"
                          style="width: 60px;"
                          v-model="item.typeValue"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="(item, index) in 4"
                            :key="item"
                            :label="index"
                            :value="index"
                            style="width: 60px"
                          >
                          </el-option>
                        </el-select>
                        <el-select
                          v-if="item.paramTypeId != 2"
                          size="mini"
                          style="width: 125px;"
                          v-model="item.dataAttr"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in constant.dataAttr"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                            style="
                              width: 125px;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            "
                          >
                            <el-tooltip
                              effect="dark"
                              :content="item.value"
                              placement="right"
                              style="width: 100%"
                            >
                              <div
                                style="
                                  width: 100%;
                                  overflow: hidden;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ item.value }}
                              </div>
                            </el-tooltip>
                          </el-option>
                        </el-select>
                        <!--  -->
                        <!-- 是否显示历史数据 -->
                        <el-switch 
                            v-model="item.isShowHistory"
                            :active-value="1"
                            :inactive-value="0"
                           @change="change($event,item.isShowHistory)">
                        </el-switch>
                      </div>
                    </div>
                    <div class="iot-page">
                      <el-pagination
                        @current-change="getCurrentPage"
                        :current-page="currentPage"
                        :page-size="50"
                        layout="total, prev, pager, next, jumper"
                        :total="paramList.length"
                        :pager-count="5"
                        :hide-on-single-page="true"
                      >
                      </el-pagination>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitSubOrForm()"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="subOrVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
let that;
import { TABLE_HEADER_COLOR } from "../util/theme";
import {
  baudrate,
  parity,
  dataBits,
  stopBits,
  ORIGINAL_IMG,
  networkModeId,
  GATEWAY_IMG,
  OSS_URL,
  dataAttr,
} from "../util/constant";
export default {
  name: "OriginalScript",
  data() {
    return {
      gatewayParamVisible: false,
      gatewayParamList: [],
      checkedBoolList: [],
      checkedStrList: [],
      seeCheckVisible: false,
      collapseName: "1",
      activeName: "",
      boolCurrentPage: 1,
      currentPage: 1,
      paramBoolList: [],
      paramNum: "",
      editSubOriginal: false,
      theme: { TABLE_HEADER_COLOR },
      constant: {
        dataAttr,
        baudrate,
        parity,
        dataBits,
        stopBits,
        networkModeId,
        ORIGINAL_IMG,
        OSS_URL,
        GATEWAY_IMG,
      },
      listData: {
        size: 9,
        current: 1,
        networkModeId: "",
        gatewayName: "",
      },
      gatewayData: [],
      total: 0,
      gatewayDialogVisibile: false,
      subOrVisible: false,
      detailDialogVisibile: false,
      rules: {
        gatewayName: [
          { required: true, message: "请输入网关名称", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入网关说明", trigger: "blur" },
        ],
        networkModeId: [
          { required: true, message: "请输入联网方式", trigger: "blur" },
        ],
      },
      rules1: {
        "subOriginal.subOriginalName": [
          { required: true, message: "请输入设备实例名称", trigger: "blur" },
        ],
      },
      gatewayForm: {
        networkModeId: 1,
        gatewayId: "",
        gatewayName: "",
        gatewayImg: "wifi.png",
        remark: "",
      },
      orForm: {
        isPublic: 1,
        industryId: 0,
      },
      isCreate: true,
      formTitle: "",
      subActiveName: "0",
      industryList: [],
      orData: [],
      subOrForm: {
        subOriginal: {
          parentId: "",
          gatewayId: "",
          subOriginalId: "",
          subOriginalName: "",
          parentName: "",
        },
        config: {
          subSerialPortId: 0,
          slaveAddr: 1,
          baudrate: 0,
          parity: "None",
          dataBits: 8,
          stopBits: 1,
        },
        params: [],
        paramList: [],
      },
      original: {
        originalName: "",
        config: {
          slaveAddr: 1,
          baudrate: 0,
          parity: "None",
        },
      },
      settingParams: [],
      subOriginalList: [],
      networkList: [],
      paramList: [],
      isIndeterminate: true,
      checkAll: false,
      strisIndeterminate: true,
      checkStrPage: false,
      paramStrPage: [],
      paramBolPage: [],
    };
  },
  methods: {
    change($event,num) {
      
    },
    updateOrder(){
      that.$api.gateway.updateOrder(that.gatewayParamList).then(res=>{
        if (res.code==200) {
           that.gatewayParamVisible = false;
        }
      })
    },
    getGatewayParams() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$api.parameter
        .getGatewayParams(that.subOrForm.subOriginal.gatewayId)
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            that.gatewayParamList = res.data;
            that.gatewayParamVisible = true;
          }
        });
    },

    del(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 查看已选中的参数
    seeChecked() {
      let boolArr = [];
      let strArr = [];
      that.subOrForm.paramList.forEach((item) => {
        if (this.getParamBoolName(item)) {
          boolArr.push({
            paramId: item,
            typeValue: this.getNum(item),
            dataAttr: this.getDataAttr(item),
            paramName: this.getParamBoolName(item),
            // isShowHistory:this.getIsShowHistory(item)
          });
        }
      });
      that.checkedBoolList = boolArr;
      that.subOrForm.paramList.forEach((item) => {
        if (this.getParamStrName(item)) {
          strArr.push({
            paramId: item,
            typeValue: this.getNum(item),
            dataAttr: this.getDataAttr(item),
            paramName: this.getParamStrName(item),
            // isShowHistory:this.getIsShowHistory(item)
          });
        }
      });
      that.checkedStrList = strArr;
      that.seeCheckVisible = true;
    },
    getParamStrName(num) {
      let name = null;
      that.paramList.forEach((item) => {
        if (num == item.paramId) {
          name = item.paramName;
        }
      });

      return name;
    },
    getParamBoolName(num) {
      let name = null;
      that.paramBoolList.forEach((item) => {
        if (num == item.paramId) {
          name = item.paramName;
        }
      });
      return name;
    },
    getCurrentPage(val) {
      that.currentPage = val;
      that.getParamPage();
    },
    //获取当前显示页数据
    getParamPage() {
      that.paramStrPage = [];
      let maxlength =
        this.currentPage * 50 >= that.paramList.length
          ? that.paramList.length
          : that.currentPage * 50;
      for (let i = (this.currentPage - 1) * 50; i < maxlength; i++) {
        that.paramStrPage.push(that.paramList[i]);
      }
              that.paramStrPage.forEach((item,index)=>{
                if(!item.isShowHistory){
                  that.$set( that.paramStrPage[index],'isShowHistory',0)
                }
              })
    },
    getBoolCurrentPage(val) {
      that.boolCurrentPage = val;
      that.getBoolParamPage();
    },
    //获取布尔显示当前显示页数据
    getBoolParamPage() {
      that.paramBolPage = [];
      let maxlength =
        this.boolCurrentPage * 50 >= that.paramBoolList.length
          ? that.paramBoolList.length
          : this.boolCurrentPage * 50;
      for (let i = (this.boolCurrentPage - 1) * 50; i < maxlength; i++) {
        that.paramBolPage.push(that.paramBoolList[i]);
      }
      that.paramBolPage.forEach((item,index)=>{
                if(!item.isShowHistory){
                  this.$set( that.paramStrPage[index],'isShowHistory',0)
                }
              })
    },
    selectData() {
      that.$set(that.listData, "current", 1);
      that.getGatewayPage(that.listData);
    },
    handleSizeChange(val) {
      that.$set(that.listData, "size", val);
      that.$set(that.listData, "current", 1);
      that.getGatewayPage(that.listData);
    },
    handleCurrentChange(val) {
      that.$set(that.listData, "current", val);
      that.getGatewayPage(that.listData);
    },
    getGatewayPage() {
      that.$api.gateway.getGatewayPage(that.listData).then((response) => {
        if (response.code == 200) {
          that.gatewayData = response.data.records;
          that.total = response.data.total;
        }
      });
    },
    getIndustryList() {
      that.$api.gateway.getIndustryList().then((response) => {
        if (response.code == 200) {
          that.industryList = response.data;
        }
      });
    },
    getModeList(name = "") {
      that.$api.gateway.getNetworkModeList(name).then((response) => {
        if (response.code == 200) {
          that.networkList = response.data;
        }
      });
    },
    selectNetworkMode() {
      that.getGatewayPage();
    },
    addGateway() {
      that.gatewayDialogVisibile = true;
      that.isCreate = true;
      that.formTitle = "新增网关";
      that.gatewayForm = {};
    },
    editGateway(id) {
      that.$api.gateway.getGateway(id).then((response) => {
        if (response.code == 200) {
          that.gatewayForm = response.data;
          that.gatewayDialogVisibile = true;
          that.isCreate = false;
          that.formTitle = "修改网关";
          that.getIndustryList();
          that.getOrList(that.orForm);
        }
      });
    },
    submitGatewayForm() {
      if (!that.gatewayForm.gatewayName) {
        that.$message({
          type: "error",
          message: "请输入网关名称！",
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // that.$refs["gatewayRef"].validate((valid) => {
      //   if (valid) {
      let _api = that.isCreate ? that.$api.gateway.addGateway(that.gatewayForm): that.$api.gateway.editGateway(that.gatewayForm);
      _api.then((response) => {
        loading.close();
        if (response.code == 200) {
          that.$message({
            type: "success",
            message: response.msg,
          });
          that.gatewayDialogVisibile = false;
          that.isCreate = true;
          that.getGatewayPage();
          that.$refs.gatewayRef.resetFields();
        }
      });
      //   }
      // });
    },
    delGateway(id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.gateway.delGateway(id).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.getGatewayPage();
            }
          });
        });
    },
    handleClose() {
      that.$set(that.orForm, "industryId", 0);
    },
    handleClick(d) {
      that.$set(that.orForm, "isPublic", Number(d.index));
      that.getOrList(that.orForm);
    },
    tabClick(id) {
      that.$set(that.orForm, "industryId", id);
      that.getOrList(that.orForm);
    },
    getOrList(data) {
      if (data.industryId == 0) {
        data.industryId = "";
      }
      that.$api.original.getOriginalList(data).then((response) => {
        if (response.code == 200) {
          that.orData = response.data;
        }
      });
    },
    getParamNum(gatewayId) {
      that.$api.gateway.getParamNum(gatewayId).then((res) => {
        if (res.code == 200) {
          that.paramNum = res.data;
        }
      });
    },

    settingDetail(gatewayId) {
      that.$set(that.subOrForm.subOriginal, "gatewayId", gatewayId);
      that.getParamNum(gatewayId);
      that.getIndustryList();
      that.getOrList(that.orForm);
      that.getSubOriginalList();
      that.detailDialogVisibile = true;
      that.formTitle = "设备实例";
    },
    getParamList(originalId) {
      that.$api.parameter.getParamStrList(originalId).then((response) => {
        if (response.code == 200) {
          that.paramList = response.data;
          that.getParamPage();
        }
      });
      that.$api.parameter.getParamBoolList(originalId).then((response) => {
        if (response.code == 200) {
          that.paramBoolList = response.data;
          that.getBoolParamPage();
        }
      });
    },
    //拿到数据渲染界面
    getSubOriginalList() {
      that.$api.gateway
        .getSubOriginalList(that.subOrForm.subOriginal.gatewayId)
        .then((response) => {
          if (response.code == 200) {
            that.subOriginalList = response.data;
          }
        });
    },
    //数据选则
    handleCheckAllChange(val) {
      let ids = new Array();
      if (val) {
        that.paramBoolList.forEach((element) => {
          ids.push(element.paramId);
        });
        that.paramList.forEach((element) => {
          ids.push(element.paramId);
        });

        that.$set(that.subOrForm, "paramList", ids);
      } else {
        that.$set(that.subOrForm, "paramList", ids);
      }
      that.isIndeterminate = false;
    },
    //数据选则
    handleCheckStrChange(val) {
      let ids = new Array();
      if (val) {
        that.paramStrPage.forEach((element) => {
          ids.push(element.paramId);
        });

        that.$set(that.subOrForm, "paramList", ids);
      } else {
        that.$set(that.subOrForm, "paramList", ids);
      }
      that.strisIndeterminate = false;
    },

    handleCheckedParams(value) {
      let checkedCount = value.length;
      that.checkAll = checkedCount === that.paramList.length;
      that.isIndeterminate =
        checkedCount > 0 && checkedCount < that.paramList.length;
      that.$set(that.subOrForm, "paramList", value);
    },
    //添加设备
    addSubOriginal(originalId) {
      if (that.subOriginalList.length == 1) {
        that.$message.warning('仅支持添加一个实例对象')
        return
      }
      that.$api.gateway.getOriginal(originalId).then((response) => {
        if (response.code == 200) {
          this.editSubOriginal = false;
          // that.original = response.data;
          let id = that.subOrForm.subOriginal.gatewayId;
          that.subOrForm = {
            subOriginal: {
              parentId: originalId,
              gatewayId: id,
              subOriginalName: response.data.subOriginalName,
            },
            config: {
              subSerialPortId: null,
              slaveAddr: Number(response.data.config.slaveAddr),
              baudrate: response.data.config.baudrate,
              parity: response.data.config.parity,
              dataBits: response.data.config.dataBits,
              stopBits: response.data.config.stopBits,
            },
            params: [],
            paramList: [],
          };

          that.getParamList(originalId);
          that.formTitle = "设备实例";
          that.isCreate = true;
          that.subOrVisible = true;
        }
      });
    },
    //拿到当前项目总数据
  async  editSubOr(item) {
      // that.getParamList(item.parentId);
      //拿到当前项目总数据 
    await  that.$api.gateway.getSubOriginal(item.subOriginalId).then((response) => {
        if (response.code == 200) {
          this.editSubOriginal = true;
          let arr = new Array();
          response.data.params.forEach((ite2) => {
            arr.push(ite2.paramId);
          })
          //获取数值列表
          that.$api.parameter.getParamStrList(item.parentId).then((res) => {
            if (res.code == 200) {
              that.paramList = res.data;
              //页面渲染的总数据
              response.data.params.forEach((ite1) => {
                that.paramList.forEach((ite) => {
                  if (ite1.paramId == ite.paramId) {
                    ite.dataAttr = ite1.dataAttr;
                    ite.typeValue = ite1.typeValue;
                    ite.paramName = ite1.paramName;
                    that.$set(ite,'isShowHistory',ite1.isShowHistory)
                  }
                });
              });
              setTimeout(() => {
                this.getParamPage();
           },100)
            }
          });
          //获取布尔列表
          that.$api.parameter.getParamBoolList(item.parentId).then((res) => {
            if (res.code == 200) {
              that.paramBoolList = res.data;
              response.data.params.forEach((item) => {
                that.paramBoolList.forEach((ite) => {
                  if (item.paramId == ite.paramId) {
                    ite.paramName = item.paramName;
                    this.$set(ite,'isShowHistory',item.isShowHistory)
                  }
                });
              });
                that.getBoolParamPage();
            }
          });

          that.subOrForm = response.data;
          that.$set(that.subOrForm, "paramList", arr);
          that.formTitle = "修改设备实例";
          that.isCreate = false;
          that.subOrVisible = true;
        }
      });
    },
    // getIsShowHistory(num) {
    //   let a = 0;
    //   that.paramList.forEach((item) => {
    //     if (num == item.isShowHistory) {
    //       a=item.isShowHistory
    //     }
    //   })
    //   return a*1
    // },
    getNum(num) {
      let a = 0;
      that.paramList.forEach((item) => {
        if (num == item.paramId) {
          a = item.typeValue;
        }
      });
      return a * 1;
    },
    getDataAttr(num) {
      let a = 0;
      that.paramList.forEach((item) => {
        if (num == item.paramId) {
          a = item.dataAttr;
        }
      });
      return a;
    },
    getIsShowHistory(num){
      let a=null;
      that.paramList.forEach((item) => {
        if(num==item.paramId) {
          a=item.isShowHistory
        }
      })
      that.paramBoolList.forEach((item) => {
        if (num == item.paramId) {
          a=item.isShowHistory
        }
      });
      return a
    },
    getParamName(num) {
      let name = null;
      that.paramList.forEach((item) => {
        if (num == item.paramId) {
          name = item.paramName;
        }
      });
      that.paramBoolList.forEach((item) => {
        if (num == item.paramId) {
          name = item.paramName;
        }
      });
      return name;
    },

    submitSubOrForm() {
      if (!that.subOrForm.subOriginal.subOriginalName) {
        that.$message({
          type: "error",
          message: "请输入设备名称！",
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      that.subOrForm.paramList.forEach((item) => {
        arr.push({
          paramId: item,
          typeValue: this.getNum(item),
          dataAttr: this.getDataAttr(item),
          paramName: this.getParamName(item),
          isShowHistory: this.getIsShowHistory(item)
        });
      });
      // console.log(that.subOrForm.paramList);
      that.subOrForm.params = arr;
      // that.$refs["subOriginalRef"].validate((valid) => {
      // if (valid) {
      let _api = that.isCreate
        ? that.$api.gateway.addSubOriginal(that.subOrForm)
        : that.$api.gateway.editSubOriginal(that.subOrForm);
      _api.then((response) => {
        loading.close();
        if (response.code == 200) {
          that.$refs.subOriginalRef.resetFields();
          that.$message({
            type: "success",
            message: response.msg,
          });
          that.isCreate = true;
          that.subOrVisible = false;
          that.getParamNum(that.subOrForm.subOriginal.gatewayId);
          that.getSubOriginalList();
        }
      });
      //   }
      // });
    },
    gatewayParamhandleClose(done){
       that.$confirm('关闭不会保存当前信息，确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    delSubOr(id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          new Promise(function (resolve) {
            that.$api.gateway.delSubOriginal(id).then((response) => {
              if (response.code == 200) {
                that.getParamNum(that.subOrForm.subOriginal.gatewayId);
                resolve();
              }
            });
          }).then(() => {
            that.getSubOriginalList();
          });
        });
    },
    closeSubOr() {
      that.detailDialogVisibile = false;
    },
  },
  beforeMount() {
    that = this;
  },
  mounted() {
    let userId = that.$store.state.userId;
    if (1 == userId) {
      that.activeName = "0";
      that.$set(that.orForm, "isPublic", 0);
    } else {
      that.activeName = "1";
      that.$set(that.orForm, "isPublic", 1);
    }
    that.getGatewayPage();
    that.getModeList();
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.clearfix form {
  position: relative;
}

.iot-create-btn {
  margin-top: 7px;
  position: absolute;
  top: 0;
  right: 0;
}

.iot-gateway-main {
  /* position: relative;
  height: calc(100% - 100px);
  margin-top: 20px;
  padding: 0;
  margin: 20px 12px 0px 12px !important; */
  position: relative;
  height: calc(100% - 100px);
  margin-top: 20px;
  width: calc(100% - 25px);
  margin: 20px 12px 0px 12px !important;
}
.iot-gateway-main >>> .el-card__header {
  padding: 0 18px;
}
.iot-gateway-main >>> .el-card__body {
  height: calc(100% - 80px);
  box-sizing: border-box;
}
.iot-gateway-main >>> .el-tabs__item:hover,
.iot-gateway-main >>> .el-tabs__item:focus,
.iot-gateway-main >>> .el-tabs__item {
  color: #303133;
}
.iot-gateway-body {
  /* display: flex;
  flex-wrap: wrap;
  align-content: space-around; */
  /* height: calc(100% - 80px); */
  height: 100%;
  margin: 10px;
  overflow-y: auto;
}
.iot-gateway-body::-webkit-scrollbar {
  width: 10px;
}
.iot-gateway-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
.iot-gateway-item {
  width: 30.6%;
  height: 18.5%;
  margin: 10px;
  border: 1px solid #ebeef5;
  border-radius: 3px;
  cursor: pointer;
}
.iot-gateway-item:hover {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.iot-gateway-item-image {
  margin-top: calc(18.5% - 50px);
  height: 100px;
  width: 100px;
  padding: 10px;
  display: inline-block;
}
.iot-gateway-item-image img,
.iot-gateway-item-image >>> .el-image {
  width: 100%;
  height: 100%;
}
.iot-gateway-item-words {
  display: flex;
  width: 65%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.iot-gateway-item-words h4,
.iot-gateway-item-words p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.iot-gateway-item-words p {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 13px;
  padding: 0 5px;
}
.iot-gateway-item-links {
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.iot-or-item {
  width: 22%;
  margin: 5px;
  cursor: pointer;
  text-align: left;
}

.iot-or-item .el-image {
  padding: 0 !important;
  display: flex;
  width: 40px;
  height: 40px;
}
.iot-or-item .or-item {
  display: flex;
  width: 100%;
}
.or-item > div {
  padding-left: 10px;
  width: calc(100% - 40px);
}
.iot-or-item > div {
  width: calc(100% - 40px);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  font-size: 12px;
}
.div1{
  pointer-events: none;
  cursor:no-drop;
}
.iot-or-item > div div {
  width: 100%;
}
.iot-or-item >>> .el-card__body {
  padding: 0;
  display: flex;
  flex-direction: row;
}
.iot-subOriginal-select-item {
  width: 45%;
  margin: 5px;
  cursor: pointer;
  text-align: left;
}
.iot-subOriginal-select-item div {
  width: calc(100% - 40px);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
  padding-left: 5px;
  font-size: 12px;
}
.iot-subOriginal-select-item >>> .el-card__body {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.iot-subOriginal-select-item >>> .el-card__body .iot-original-name {
  flex: 1;
  padding-left: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.iot-subOriginal-select-item >>> .el-button--mini {
  height: 28px;
  margin: 5px;
}

.iot-dialog >>> .el-dialog__body {
  height: 581px;
  overflow-y: auto;
}
.iot-dialog >>> .el-tabs__nav-wrap::after {
  background-color: rgba(0, 0, 0, 0);
}
.iot-dialog >>> .el-select,
.el-input {
  width: 100%;
}

.iot-type-items {
  display: flex;
  flex-wrap: wrap;
}
.iot-type-item {
  color: #000;
  font-size: 12px;
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}
.iot-gateway-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  line-height: 40px;
  max-height: 160px;
  overflow-y: auto;
}

.iot-subOriginal-items {
  width: 100%;
  max-height: 350px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  line-height: 20px;
}

/* .iot-param-items >>> .el-checkbox {
  width: 150px;
} */
.iot-param-form {
  position: relative;
}
.iot-param-form >>> .el-checkbox {
  width: 150px;
}
.iot-param-form >>> .el-form-item__content {
  margin-left: 0 !important;
}
.remark {
  margin: 5px;
  width: 25%;
  font-size: 12px;
  height: 48px;
  /* overflow: hidden; 
  text-overflow: ellipsis;  */
  color: #aaa;
  display: inline-block;
}
.create-dialog >>> .el-dialog {
  height: 40%;
}
.gateway-dialog >>> .el-dialog {
  height: 775px;
}
.gateway-dialog >>> .el-dialog__body {
  padding: 0;
}
.subOriginal-dialog >>> .el-dialog {
  width: 50%;
  height: 775px;
}
.subOriginal-dialog >>> .el-dialog__body {
  padding: 0;
}

.subOriginal-dialog >>> .el-form-item__content {
  line-height: 20px;
  margin-bottom: 10px;
}

.iot-gateway-form {
  width: 91%;
  height: 668px;
  margin-left: 3%;
  padding: 20px;
}

.iot-subOriginal-form {
  width: 100%;
  height: 668px;
  overflow-y: auto;
  /* margin-left: 3%; */
  padding: 0 5%;
}
.iot-subOriginal-form::-webkit-scrollbar {
  width: 10px;
}
.iot-subOriginal-form::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
.subOriginal-dialog >>> .el-dialog__footer {
  text-align: center;
}
.gateway-but {
  display: flex;
  /* margin-right: 20px; */
  align-items: center;
  justify-content: center;
}
::v-deep .el-icon-arrow-right {
  margin-left: 10px !important;
}
.iot-page {
  display: flex;
  justify-content: flex-end;
}
.paramInput {
  padding: 0 0 0 5px;
  width: 120px;
  display: inline-block;
  height: 30px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #c0c4cc;
  outline: none;
}
.paramInput:focus {
  border: 1px solid #409eff;
}
.checkItem {
  width: 100px;
  margin: 5px 10px;
  text-align: center;
}
.checkStrItem {
  width: 200px;
  margin: 5px 10px;
  text-align: center;
}
.getwayItem {
  width: 200px;
  margin: 5px 10px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 !important;
  justify-content: space-between;
}
.getwayItem .el-input{
  width: 60px !important;
} 

.tips {
  color: #999;
  font-size: 12px;
  margin: 5px 0;
}
</style>
